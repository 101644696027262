













































/* eslint-disable */
import Vue from "vue";
export default Vue.extend({
  name: "AuthCard",
  props: {
    title: {
      type: String,
    },
    authModal: {
      type: Boolean,
    },
    titleAction: {
      type: String,
    },
    email: {
      type: String,
    },
    password: {
      type: String,
    },
    methodAction: {
      type: Function,
    },
  },
  methods: {
    closeModal() {
      this.$props.authModal = false;
    },
    openModal() {
      this.$props.authModal = true;
    },
  },
  mounted() {
    this.$props.methodAction();
  },
});
