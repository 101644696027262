<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="titlePage" :menu="menu" />
    </v-row>
    <v-dialog v-model="authModal" persistent max-width="500" v-if="productSelected">
      <v-card elevation="0">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="overline">{{ titleAuth }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="closeAuthModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="mt-n5">
                <p class="overline">Venda R$ {{ formatPrice(productSelected.price) }}</p>
              </v-col>
              <v-col cols="6" class="mt-n5">
                <p class="overline">
                  Informado R$ {{ formatPrice(itemProduct.unitPrice) }}
                </p>
              </v-col>
              <v-col cols="6" class="mt-n5">
                <p class="overline">
                  A Liberar
                  {{ formatPrice(itemProduct.discount) }}
                </p>
              </v-col>
              <v-col cols="6" class="mt-n5">
                <p class="overline">
                  Max. Item R$
                  {{ formatPrice(itemProduct.max) }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="authModel.username"
                  label="Usuário"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="authModel.password"
                  label="Senha"
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n5">
                <v-btn
                  :loading="loadingAction"
                  dark
                  color="green"
                  width="100%"
                  @click="authorizeDiscount"
                >
                  {{ titleAction }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="mt-n4">
                <v-btn dark color="red" width="100%" @click="closeAuthModal">
                  CANCELAR
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="addProductModal" width="900px">
      <v-container>
        <v-card style="padding: 10px !important">
          <v-card-title>Novo Produto/Serviço</v-card-title>
          <v-row>
            <v-col cols="12" md="7">
              <v-autocomplete
                outlined
                :items="products"
                v-model="productSelected"
                label="Produto/Serviço"
                item-text="label"
                @change="selectItem"
                return-object
                append-icon="mdi-magnify"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="6"
              md="2"
              v-if="
                !productSelected ||
                (productSelected && productSelected.productType === 'PRODUTO')
              "
            >
              <v-text-field
                type="number"
                outlined
                v-model="itemProduct.quantity"
                label="Quantidade"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              :md="
                !productSelected ||
                (productSelected && productSelected.productType === 'PRODUTO')
                  ? '3'
                  : '4'
              "
              class="mt-n6"
            >
              <span>Preço UN R$</span>
              <money
                class="money"
                outlined
                v-model="itemProduct.unitPrice"
                label="Preço Unitário"
                v-bind="money"
                @blur.native="checkMaxDiscount()"
              ></money>
            </v-col>
          </v-row>
          <v-row v-if="productSelected && productSelected.productType === 'SERVICO2'">
            <v-col cols="12">
              <v-checkbox
                v-model="requiredSchedule"
                label="Realizar Agendamento?"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row
            v-if="
              productSelected &&
              requiredSchedule &&
              productSelected.productType === 'SERVICO'
            "
          >
            <v-col cols="12" md="4">
              <v-autocomplete
                outlined
                :items="employees"
                v-model="itemProduct.professionalExecutorId"
                @change="changeSchedules"
                label="Profissional"
                item-text="person.corporateName"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="date"
                outlined
                @change="changeSchedules"
                v-model="scheduleDate"
                label="Data"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-autocomplete
                outlined
                @change="changeTime"
                :items="schedules"
                v-model="scheduleSelected"
                label="Horário"
                item-text="label"
                return-object
                multiple
                :loading="loadingSchedule"
                no-data-text="Não existe agenda disponível nesta data. Favor Selecionar outra data"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  :loading="loadingAction"
                  block
                  dark
                  class="light-green accent-4"
                  @click="addOrUpdateItem"
                  >Adicionar</v-btn
                >
              </v-col>
              <v-col cols="12" class="mt-n4">
                <v-btn block @click="closeModalItem">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog>
      <v-card class="mx-auto">
        <v-col cols="12" sm="4" class="mt-n3">
          <v-autocomplete
            outlined
            :items="accounts"
            v-model="accountId"
            label="Conta"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="3">
          <v-select
            outlined
            :items="plans"
            item-text="label"
            item-value="id"
            v-model="paymentPlan"
            label="Plano de Pagamento"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-select>
        </v-col>
        <v-col cols="6" md="3" v-if="paymentPlan === 'R' || paymentPlan === 'I'">
          <v-select
            outlined
            :items="[
              { id: 'W', label: 'Semanal' },
              { id: 'M', label: 'Mensal' },
              { id: 'Y', label: 'Anual' },
            ]"
            item-text="label"
            item-value="id"
            v-model="frequencyType"
            label="Frquencia"
          ></v-select>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            outlined
            :items="paymentMethods"
            v-model="paymentMethodId"
            label="Forma de Pagamento"
            item-text="title"
            item-value="id"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-autocomplete>
        </v-col>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-card min-width="95%">
        <v-card-text>
          <v-row class="mr-8 ml-8">
            <v-col cols="6" md="4">
              <span class="title-sale">Cliente</span>
              <p class="overline text-subtitle-2 mt-n1">{{ customerName }}</p>
            </v-col>
            <v-col cols="6" md="4">
              <span class="title-sale">Total</span>
              <p class="overline text-subtitle-2 mt-n1">
                R$ {{ formatPrice(amountLiquid) }}
              </p></v-col
            >
            <v-col cols="12" md="4">
              <span class="title-sale">Plano de Pagamento</span>
              <p class="overline text-subtitle-2 mt-n1">
                {{ paymentFactory() }}
              </p></v-col
            >
          </v-row>
          <v-container v-if="!loading">
            <br />
            <v-stepper v-model="stage" :vertical="false">
              <v-stepper-step :complete="stage > 1" step="1">
                Dados Gerais
                <small
                  >Cliente e vendedor
                  <v-btn v-if="stage > 1" icon x-small>
                    <v-icon @click="stage = 1">mdi-pencil</v-icon></v-btn
                  >
                </small>
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      outlined
                      :items="customers"
                      v-model="customerId"
                      label="Cliente"
                      item-text="person.corporateName"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                      outlined
                      :items="employees"
                      v-model="salesmanId"
                      label="Vendedor"
                      item-text="person.corporateName"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-btn
                  block
                  :disabled="!customerId || !salesmanId"
                  :loading="loadingAction"
                  color="primary"
                  @click="oneStage"
                >
                  Continuar
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="stage > 2" step="2"> Produtos </v-stepper-step>

              <v-stepper-content step="2">
                <v-btn
                  @click="prepareModalItem(false)"
                  color="green accent-4"
                  block
                  dark
                  class="mt-4 mb-4"
                >
                  Adicionar Novo Item</v-btn
                >
                <v-data-table
                  :loading="loading"
                  :headers="headerItems"
                  :items="items"
                  disable-pagination
                  hide-default-footer
                  disable-sort
                  no-data-text="Adicione produtos para continuar"
                >
                  <template v-slot:[`item.unitPrice`]="{ item }">
                    <span>R$ {{ formatPrice(item.unitPrice) }}</span>
                  </template>

                  <template v-slot:[`item.totalPrice`]="{ item }">
                    <span>R$ {{ formatPrice(item.totalPrice) }}</span>
                  </template>

                  <template v-slot:[`item.discountTotal`]="{ item }">
                    <span>R$ {{ formatPrice(item.discountTotal) }}</span>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn text small @click="editItem(item)">
                      <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
                    >
                    <v-btn text small @click="removeItem(item)" :loading="loadingAction">
                      <v-icon small class="mr-2">mdi-eraser</v-icon></v-btn
                    >
                  </template>
                </v-data-table>

                <v-btn
                  class="mt-3"
                  :loading="loadingAction"
                  :disabled="!items.length"
                  block
                  color="primary"
                  @click="setProducts(false)"
                >
                  Seguir para Pagamento
                </v-btn>
                <v-btn
                  outlined
                  class="mt-3"
                  :loading="loadingAction"
                  :disabled="!items.length"
                  block
                  color="primary"
                  @click="setProducts(true)"
                >
                  Finalizar sem Pagamento
                </v-btn>
                <v-btn class="mt-2" block @click="stage = 1" text> Voltar </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="stage > 3" step="3">
                Forma de Pagamento
              </v-stepper-step>

              <v-stepper-content step="3">
                <p class="text-caption ml-4">Total a Pagar</p>
                <p class="overline text-h6 mt-n5 ml-4 mb-8">
                  R$ {{ formatPrice(amountLiquid) }}
                </p>
                <v-row>
                  <v-col cols="12" md="7">
                    <v-select
                      outlined
                      :items="plans"
                      item-text="label"
                      item-value="id"
                      v-model="paymentPlan"
                      label="Plano de Pagamento"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4" v-if="paymentPlan === 'AVISTA'">
                    <v-autocomplete
                      outlined
                      :items="paymentMethods"
                      v-model="paymentMethodId"
                      label="Forma de Pagamento"
                      item-text="title"
                      item-value="id"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="paymentPlan === 'PARCELADO_COM_ENTRADA'">
                  <v-col cols="12" md="4" class="mt-n6">
                    <span>Entrada R$</span>
                    <money class="money" v-bind="money" v-model="amountPaid"></money>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-autocomplete
                      outlined
                      :items="paymentMethods"
                      v-model="paymentMethodId"
                      label="Forma de Pagamento Entrada"
                      item-text="title"
                      item-value="id"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="paymentPlan && paymentPlan !== 'AVISTA'">
                  <v-col cols="5" md="2">
                    <v-text-field
                      outlined
                      v-model="frequencyTotal"
                      label="Total de Parcelas"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="7" md="3">
                    <v-select
                      outlined
                      :items="[
                        { id: 'W', label: 'Semanal' },
                        { id: 'M', label: 'Mensal' },
                        { id: 'Y', label: 'Anual' },
                      ]"
                      item-text="label"
                      item-value="id"
                      v-model="frequencyType"
                      label="Prazo Parcelas"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      label="Vencimento Inicial"
                      v-model="initialDueDate"
                      type="date"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      outlined
                      :items="paymentMethods"
                      v-model="instalmentMethodId"
                      label="Forma de Pagamento Parcelas"
                      item-text="title"
                      item-value="id"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-btn :loading="loadingAction" block color="primary" @click="setPayment">
                  Registrar Pagamento
                </v-btn>
                <v-btn text class="mt-2" block @click="stage = 2"> Voltar </v-btn>
              </v-stepper-content>

              <v-stepper-step
                :complete="stage > 4"
                step="4"
                v-if="
                  paymentPlan === 'PARCELADO_COM_ENTRADA' ||
                  paymentPlan === 'PARCELADO_SEM_ENTRADA'
                "
              >
                Parcelas
              </v-stepper-step>
              <v-stepper-content
                step="4"
                v-if="
                  paymentPlan === 'PARCELADO_COM_ENTRADA' ||
                  paymentPlan === 'PARCELADO_SEM_ENTRADA'
                "
              >
                <v-card class="mx-auto">
                  <v-data-table
                    :loading="loading"
                    :headers="hins"
                    :items="instalments"
                    hide-default-footer
                    disable-pagination
                    :header-props="{ sortByText: 'Ordenar por' }"
                  >
                    <template v-slot:[`item.amount`]="{ item }">
                      <v-edit-dialog
                        :return-value.sync="item.amount"
                        @save="save(item)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                      >
                        R$ {{ formatPrice(item.amount) }}
                        <template v-slot:input>
                          <money
                            v-model="item.amount"
                            label="Edit"
                            single-line
                            v-bind="money"
                          ></money>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:[`item.dueDate`]="{ item }">
                      <v-edit-dialog
                        :return-value.sync="item.dueDate"
                        @save="save(item)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                      >
                        {{ dateFormat(item.dueDate) }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="item.dueDate"
                            label="Novo Vencimento"
                            single-line
                            type="date"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.number`]="{ item }">
                      <span>{{ `${item.frequencyNumber}/${item.frequencyTotal}` }}</span>
                    </template>
                  </v-data-table>
                  <v-card-actions>
                    <v-col cols="12">
                      <v-btn
                        :loading="loadingAction"
                        dark
                        width="100%"
                        color="#2ca01c"
                        @click="saveAll()"
                        >Finalizar Venda</v-btn
                      >
                    </v-col>
                  </v-card-actions>
                </v-card>
                <v-btn text @click="stage = 3"> Voltar</v-btn>
              </v-stepper-content>
            </v-stepper>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="blue darken-1" to="/base/sales"> Voltar </v-btn>
              <v-btn
                v-if="saleId"
                dark
                :loading="loadingAction"
                width="200px"
                color="#2ca01c"
                @click="stage = 1"
              >
                Reiniciar
              </v-btn>
            </v-card-actions>
          </v-container>
          <v-container v-else>
            <v-col cols="12">
              <v-skeleton-loader
                height="400px"
                width="800px"
                type="article, actions"
              ></v-skeleton-loader>
            </v-col>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import AuthCard from "@/components/component/AuthCard.vue";

export default Vue.extend({
  title: "Venda",
  components: { Money, PageHeader, InternalMenu, AuthCard },
  data: () => ({
    titlePage: "Nova Venda",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Classificações Financeiras",
            to: "/finance/categories",
            permission: "finance.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Métodos de Pagamento",
            to: "/finance/payment-methods",
            permission: "finance.payment_methods.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Transações Financeiras",
            to: "/finance/movements",
            permission: "finance.transactions.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Vendas",
        disabled: false,
        href: "/base/sales",
      },
      {
        text: "Nova Venda",
        disabled: true,
        href: "/base/sale/create",
      },
    ],

    valid: true,
    accounts: [],
    movements: [],
    paymentMethods: [],
    categories: [],
    members: [],
    customers: [],
    employees: [],
    products: [],
    items: [],
    search: "",
    loading: false,
    loadingAction: false,
    stage: 1,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 7000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    title: "",
    referenceDate: null,
    amount: "",
    categoryId: null,
    accountId: null,
    paymentMethodId: null,
    title: "",
    type: "VENDA",
    salesmanId: null,
    dueDate: new Date(),
    supplierId: null,
    customerId: null,
    paid: true,
    paymentPlan: "AVISTA",
    frequencyType: null,
    frequencyTotal: 3,
    openDialog: false,
    fullScreen: false,
    instalments: [],
    instalmentDialog: false,
    initialAmount: 0,
    amountPaid: 0,
    initialDueDate: null,
    instalmentMethodId: null,
    amountLiquid: 0,
    frequencyCalc: 0,
    saleId: null,
    customerName: "-",
    itemId: null,
    productSelected: null,
    schedules: [],
    scheduleSelected: [],
    scheduleDate: moment().format("YYYY-MM-DD"),
    loadingSchedule: false,
    itemProduct: {
      productId: null,
      saleId: null,
      unitPrice: "",
      quantity: 1,
      discount: "",
      professionalExecutorId: null,
    },
    hins: [
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: false,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Valor",
        align: "start",
        value: "amount",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        align: "start",
        value: "paymentMethod.title",
        filtering: true,
      },
    ],
    plans: [
      {
        id: "AVISTA",
        label: "Avista",
      },
      {
        id: "PARCELADO_COM_ENTRADA",
        label: "Parcelado C/ Entrada",
      },
      {
        id: "PARCELADO_SEM_ENTRADA",
        label: "Parcelado S/ Entrada",
      },
    ],
    headers: [],
    headerItems: [
      {
        text: "Descrição",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "QTD",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Preço UN R$",
        align: "start",
        value: "unitPrice",
        filtering: true,
      },
      {
        text: "Desconto R$",
        align: "start",
        value: "discountTotal",
        filtering: true,
      },
      {
        text: "Total R$",
        align: "start",
        value: "totalPrice",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
    /* modals */
    addProductModal: false,
    hasSale: true,
    authModal: false,
    titleAction: "Autorizar",
    titleAuth: "Liberar Desconto",
    requiredSchedule: false,
    /* Instalments */
    instalments: [],

    /* AuthCard */
    authModel: {
      username: "",
      password: "",
    },
    professionalId: null,
  }),
  methods: {
    changeTime(event) {
      const time = this.productSelected.timeService || 60;
      const countTimes = time / 15;
      const start = this.schedules.findIndex((a) => a.id === event[0].id);

      const x = this.schedules.slice(start, start + countTimes);
      if (x.length < countTimes) {
        this.snackbar.text = `O Tempo de execução do serviço, ultrapassa a agenda disponível, 
          Para agendar mesmo assim, clique em adicionar ou escolha um novo horário`;
        this.snackbar.color = "orange";
        this.snackbar.opened = true;
        this.snackbar.timeout = 5000;
      }
      this.scheduleSelected = x;
    },
    changeSchedules() {
      this.loadingSchedule = true;
      if (!this.itemProduct.professionalExecutorId) {
        this.scheduleSelected = [];
        this.addProductModal = true;
        this.loadingSchedule = false;
        return;
      }
      http
        .get(
          `schedules?filter=professionalId||eq||${this.itemProduct.professionalExecutorId}&filter=saleId||$isnull&filter=startTime||gte||${this.scheduleDate}T00:00:00&filter=endTime||lte||${this.scheduleDate}T23:59:59`
        )
        .then(
          (a) => {
            this.schedules = a.data;
            this.loadingSchedule = false;
          },
          () => {
            this.loadingSchedule = false;
          }
        );
    },
    selectItem() {
      this.itemProduct.productId = this.productSelected.id;
      this.itemProduct.unitPrice = this.productSelected.price;
      this.checkMaxDiscount();
    },
    prepareModalItem(editiMode) {
      if (!editiMode) this.filterProducts();

      if (this.type === "AGENDAMENTO" && editiMode) {
        this.changeSchedules();
        if (!this.itemProduct.professionalExecutorId) {
          this.scheduleSelected = [];
          this.addProductModal = true;
          return;
        }
        http
          .get(
            `schedules?filter=professionalId||eq||${this.itemProduct.professionalExecutorId}&filter=saleId||eq||${this.itemProduct.saleId}&filter=isMain||eq||false`
          )
          .then((a) => {
            this.schedules.push(...a.data);
            this.scheduleSelected = a.data;
            this.addProductModal = true;
          });
      } else {
        this.addProductModal = true;
      }
    },
    filterProducts() {
      const itemIds = this.items.map((a) => a.productId);
      this.products = this.products.filter((a) => !itemIds.includes(a.id));
    },
    checkMaxDiscount() {
      const discount = this.productSelected.price * 1 - this.itemProduct.unitPrice;
      const max =
        (this.productSelected.price *
          1 *
          (this.productSelected.maximumPercentDiscount * 1)) /
        100;
      if (max < discount) {
        this.itemProduct.discount = discount;
        this.itemProduct.max = max;
        this.authModal = true;
      }
    },
    authorizeDiscount() {
      this.itemProduct.username = this.authModel.username;
      this.itemProduct.password = this.authModel.password;
      this.closeAuthModal();
    },
    closeAuthModal() {
      this.authModal = false;
      this.authModel.username = null;
      this.authModel.password = null;
    },
    paymentFactory() {
      if (!this.paymentPlan) return "-";
      if (this.paymentPlan === "AVISTA") return "Avista";
      if (this.paymentPlan === "PARCELADO_COM_ENTRADA" && this.frequencyTotal)
        return `Entrada de R$ ${this.formatPrice(this.amountPaid)} + ${
          this.frequencyTotal
        } Parcelas`;

      if (this.paymentPlan === "PARCELADO_SEM_ENTRADA" && this.frequencyTotal)
        return `Parcelado S/ Entrada em ${this.frequencyTotal}x`;
      return "-";
    },
    async editItem(item) {
      this.itemProduct = item;
      this.itemId = item.id;
      this.productSelected = item.product;
      this.prepareModalItem(true);
    },
    closeModalItem() {
      this.itemId = null;
      this.itemProduct.productId = null;
      this.itemProduct.quantity = 1;
      this.itemProduct.unitPrice = "";
      this.itemProduct.password = null;
      this.itemProduct.username = null;
      this.addProductModal = false;
      this.productSelected = null;
      this.itemProduct.professionalExecutorId = null;
      this.scheduleSelected = [];

      this.getProducts();
      this.getSaleItems();
    },
    getSaleItems() {
      http.get(`gerentor/sale/${this.saleId}/items`).then((response) => {
        this.items = response?.data;
      });
    },
    removeItem(item) {
      this.loadingAction = true;
      http.delete(`gerentor/sale/${item.saleId}/items/${item.id}`).then(
        () => {
          http.get(`gerentor/sale/${item.saleId}/items`).then(
            (response) => {
              this.items = response?.data;
              this.loadingAction = false;
            },
            () => (this.loadingAction = false)
          );
        },
        () => (this.loadingAction = false)
      );
    },
    async setPayment() {
      const payload = {
        paymentMethodId: this.paymentMethodId,
        paymentPlan: this.paymentPlan,
        instalmentMethodId: this.instalmentMethodId,
        initialDueDate: this.initialDueDate,
        amountPaid: this.amountPaid,
        frequencyType: this.frequencyType,
        frequencyTotal: this.frequencyTotal,
      };
      this.loadingAction = true;
      await http
        .put(`gerentor/sales/${this.saleId}/payment`, payload)
        .then(async (res) => {
          const { data } = res;
          this.stage = data.stage;
          if (this.paymentPlan === "AVISTA") {
            this.snackbar.text = "Venda Inserida com Sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            setTimeout(() => {
              this.$router.push("/base/sales/billing");
            }, 1000);
          } else {
            this.paymentPlan = data.paymentPlan;
            if (
              this.paymentPlan === "PARCELADO_SEM_ENTRADA" ||
              this.paymentPlan === "AVISTA"
            ) {
              this.frequencyTotal = data.frequencyTotal;
            }
            if (this.paymentPlan === "PARCELADO_COM_ENTRADA") {
              this.frequencyTotal = data.frequencyTotal - 1;
            }

            this.amountLiquid = data.amountLiquid;
            this.amountPaid = data.amountPaid;
            this.frequencyType = data.frequencyType;
            await http
              .get(`finance/transactions?filter=saleId||eq||${data.id}`)
              .then((transaction) => {
                this.instalments = transaction.data;
              });
          }
        });
      this.loadingAction = false;
    },
    async saveAll() {
      this.loadingAction = true;
      for await (const instalment of this.instalments) {
        await http.put(`finance/transactions/${instalment.id}`, instalment);
      }
      await http
        .patch(`gerentor/sales/${this.saleId}`, { status: "FECHADO", stage: 5 })
        .then((res) => {
          this.snackbar.text = "Parcelas Atualizadas com Sucesso";
          this.snackbar.color = "success";
          this.snackbar.opened = true;
          this.$router.push("/base/sales/billing");
        });
      this.loadingAction = false;
    },
    setProducts(finishSale = false) {
      this.loadingAction = true;
      http.put(`gerentor/sales/${this.saleId}/set-items`).then((res) => {
        const { data } = res;
        if (this.type === "AGENDAMENTO" && finishSale) {
          this.$router.push("/schedules");
          return;
        }
        this.stage = data.stage;
        this.amountLiquid = data.amountLiquid;
      });
      this.loadingAction = false;
    },
    addOrUpdateItem() {
      if (!this.saleId) {
        this.snackbar.color = "orange";
        this.snackbar.text = "Pra Adicionar um Produto Inicie a Venda";
        this.snackbar.opened = true;
        return;
      }
      this.itemProduct.totalPrice =
        this.itemProduct.unitPrice * this.itemProduct.quantity;
      this.itemProduct.schedules = this.scheduleSelected;
      if (
        this.productSelected.productType === "SERVICO" &&
        !this.scheduleSelected.length &&
        this.requiredSchedule
      ) {
        this.snackbar.color = "red";
        this.snackbar.text = "Impossível adicionar Serviço sem selecionar horário";
        this.snackbar.opened = true;
        return;
      }
      if (this.itemId) {
        this.loadingAction = true;
        http
          .put(`gerentor/sale/${this.saleId}/items/${this.itemId}`, this.itemProduct)
          .then(
            () => {
              if (
                this.productSelected.productType === "SERVICO" &&
                this.scheduleSelected.length
              ) {
                const payload = {
                  title: this.productSelected.name,
                  schedules: this.scheduleSelected,
                  itemId: this.itemProduct.productId,
                };
                http.post(`schedules/sale/${this.saleId}`, payload).then(() => {
                  this.loadingAction = false;
                  this.snackbar.color = "success";
                  this.snackbar.text = "Serviço Adicionado com Sucesso!";
                  this.snackbar.opened = true;
                  this.closeModalItem();
                });
              } else {
                this.loadingAction = false;
                this.snackbar.color = "success";
                this.snackbar.text = "Produto Alterado com Sucesso!";
                this.snackbar.opened = true;
                this.closeModalItem();
              }
            },
            (err) => {
              this.loadingAction = false;
              const { response } = err;
              const message = response?.data?.message || "Erro ao Editar Item";
              this.snackbar.color = "red";
              this.snackbar.text = message;
              this.snackbar.opened = true;
            }
          );
      } else {
        this.loadingAction = true;
        http.post(`gerentor/sale/${this.saleId}/items`, this.itemProduct).then(
          (res) => {
            if (
              this.productSelected.productType === "SERVICO" &&
              this.scheduleSelected.length
            ) {
              const payload = {
                title: this.productSelected.name,
                schedules: this.scheduleSelected,
                itemId: res.data.productId,
              };
              http.post(`schedules/sale/${this.saleId}`, payload).then(() => {
                this.loadingAction = false;
                this.snackbar.color = "success";
                this.snackbar.text = "Serviço Adicionado com Sucesso!";
                this.snackbar.opened = true;
                this.closeModalItem();
              });
            } else {
              this.loadingAction = false;
              this.snackbar.color = "success";
              this.snackbar.text = "Produto Adicionado com Sucesso!";
              this.snackbar.opened = true;
              this.closeModalItem();
            }
          },
          (err) => {
            this.loadingAction = false;
            const { response } = err;
            const message = response?.data?.message || "Erro ao Adicionar Item";
            this.snackbar.color = "red";
            this.snackbar.text = message;

            this.snackbar.opened = true;
          }
        );
      }
    },
    save(item) {
      if (this.paymentPlan === "PARCELADO_COM_ENTRADA") this.calculateInstalmentOn(item);

      if (this.paymentPlan === "PARCELADO_SEM_ENTRADA") this.calculateInstalmentOff(item);
    },
    calculateInstalmentOff(item) {
      let start = item.frequencyNumber - 1;
      const total = Number.parseFloat(this.amountLiquid);
      const interval = this.frequencyTotal - item.frequencyNumber;

      let amountCalc = 0;
      let point = 0;
      amountCalc += Number.parseFloat(this.instalments[0].amount);
      while (point < start) {
        point++;
        amountCalc += Number.parseFloat(this.instalments[point].amount);
      }
      const diference = total - amountCalc;
      const parcel = diference / interval;

      while (start < this.frequencyTotal) {
        start++;
        this.instalments[start - 1].amount = parcel;
      }
    },
    calculateInstalmentOn(item) {
      let start = item.frequencyNumber - 1;
      const frequency = Number.parseInt(this.frequencyTotal) + 1;
      const total = Number.parseFloat(this.amountLiquid);
      const interval = frequency - item.frequencyNumber;

      let amountCalc = 0;
      let point = 0;
      amountCalc += Number.parseFloat(this.instalments[0].amount);
      while (point < start) {
        point++;
        amountCalc += Number.parseFloat(this.instalments[point].amount);
      }
      const diference = total - amountCalc;
      const parcel = diference / interval;

      while (start < frequency) {
        start++;
        this.instalments[start - 1].amount = parcel;
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    async getSale() {
      this.loading = true;
      const saleId = this.$route.params.id;
      if (saleId) {
        this.saleId = saleId;
        await http.get(`gerentor/sales/${saleId}`).then(async (res) => {
          const { data } = res;
          this.salesmanId = data.salesmanId;
          this.customerId = data.customerId;
          this.type = data.type;
          this.stage = data.stage;
          this.saleId = data.id;
          this.paymentPlan = data.paymentPlan;
          this.paymentMethodId = data.paymentMethodId;
          this.instalmentMethodId = data.instalmentPaymentMethodId;
          this.amountLiquid = data.amountLiquid;
          this.type = data.type;
          if (
            this.paymentPlan === "PARCELADO_SEM_ENTRADA" ||
            this.paymentPlan === "AVISTA"
          ) {
            this.frequencyTotal = data.frequencyTotal;
          }
          if (this.paymentPlan === "PARCELADO_COM_ENTRADA") {
            this.frequencyTotal = data.frequencyTotal - 1;
          }
          this.amountLiquid = data.amountLiquid;
          this.amountPaid = data.amountPaid;
          this.frequencyType = data.frequencyType;
          this.customerName = data?.customer?.person?.corporateName;
          await http.get(`gerentor/sale/${data.id}/items`).then((response) => {
            this.items = response?.data;
          });
          await http
            .get(`finance/transactions?filter=saleId||eq||${data.id}`)
            .then((transaction) => {
              this.instalments = transaction.data;
              if (this.instalments?.length > 1) {
                const one = this.instalments.find((d) => d.frequencyNumber == 2);
                this.initialDueDate = one?.dueDate;
              }
            });
        });
      }
      this.loading = false;
    },
    oneStage() {
      this.loadingAction = true;
      const payload = {
        customerId: this.customerId,
        salesmanId: this.salesmanId,
        type: this.type,
        stage: 2,
      };
      if (this.saleId) {
        http.put(`gerentor/sales/${this.saleId}`, payload).then((res) => {
          const { data } = res;
          this.stage = data.stage;
          this.getSale();
          //this.customerName = data?.customer?.person?.corporateName;
        });
      } else {
        http.post(`gerentor/sales`, payload).then((res) => {
          const { data } = res;
          this.$router.push(`/base/sale/${data.id}/edit`);
          this.saleId = data.id;
          this.stage = data.stage;
          this.getSale();

          /*     this.customerId = data.customerId;
          this.salesmanId = data.salesmanId;
          this.type = data.type;
          this.stage = data.stage;*/
        });
      }
      this.loadingAction = false;
    },

    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      if (!type) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return type === "C"
        ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM/YYYY");
    },
    getEmployees() {
      this.loading = true;
      http.get("gerentor/employees").then((data) => {
        this.employees = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    getPayments() {
      this.loading = true;
      http.get("finance/payment-methods").then((data) => {
        this.paymentMethods = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then(
        (data) => {
          this.accounts = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
    getProducts() {
      this.loading = true;
      http.get("products").then(
        (data) => {
          this.products = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
    getCustomers() {
      this.loading = true;
      http.get("gerentor/customers").then(
        (data) => {
          this.customers = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
    getCategories() {
      this.loading = true;
      http.get("finance/categories").then(
        (data) => {
          this.categories = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },

    registerOrUpdate() {
      this.loadingAction = true;
      let payload = {
        referenceDate: this.referenceDate,
        paid: this.paid,
        datetime: new Date(),
        dueDate: this.dueDate,
        paymentPlan: this.paymentPlan,
        type: this.type,
        paymentMethodId: this.paymentMethodId,
        title: this.title,
        accountId: this.accountId,
        categoryId: this.categoryId,
        amount: this.amount,
        customerId: this.customerId,
        supplierId: this.supplierId,
        frequencyType: this.frequencyType,
        frequencyTotal: this.paymentPlan === "S" ? 1 : this.frequencyTotal,
        frequencyNumber: this.paymentPlan === "S" ? 1 : null,
      };

      http.post("finance/transactions", payload).then(
        (res) => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Inserido com Sucesso!";
          this.snackbar.opened = true;
          this.loadingAction = false;
          if (this.paymentPlan === "I") {
            this.instalments = res.data;
            this.instalmentDialog = true;
          }
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },
    updatePaid(id, e) {
      this.loadingAction = true;
      http.patch(`finance/transactions/${id}`, { paid: e }).then(
        () => {
          this.loadingAction = false;
          this.snackbar.text = "Transação Atualizada";
          this.snackbar.color = "success";
          this.snackbar.opened = true;
        },
        () => {
          this.loadingAction = false;
          this.snackbar.text = "Erro ao Atualizar Transação";
          this.snackbar.color = "red";
          this.snackbar.opened = true;
        }
      );
    },
    closeDialog() {
      this.$refs.form.reset();
      this.id = "";
      this.amount = "";
      this.openDialog = false;
    },
    mountParams() {
      const type = this.$route.query.type;
      if (type) this.type = type;
    },
  },

  async mounted() {
    this.mountParams();
    await this.getSale();
    this.getEmployees();
    this.getAccounts();
    this.getCategories();
    this.getPayments();
    this.getProducts();
    this.getCustomers();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 57px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
.title-sale {
  font-size: 10px;
  color: grey;
}
.card-sale {
  font-size: 19px !important;
}
</style>
